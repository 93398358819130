import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RouteEnum from '../../RouteEnum';
import { useRouteContext } from '../../RouteProvider';
import type { ReduxState } from '../../store';
import { signOut } from '../../store/session';
import PageLayout from '../../views/Layout/Page';
import Loader from '../../views/Loader';

export default function SignOutPage() {
  const dispatch = useDispatch();
  const user = useSelector<ReduxState>(store => store.user);
  const { navigate } = useRouteContext();

  useEffect(() => {
    if (user !== null) return;
    navigate(RouteEnum.HOMEPAGE, { replace: true });
  }, [user]);

  useEffect(() => {
    dispatch(signOut());
  }, []);

  return (
    <PageLayout pageview="logout">
      <Loader />
    </PageLayout>
  );
}
